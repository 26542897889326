import request from '../utils/request'

/**
 * 获取字典列表
 * @returns {AxiosPromise}
 */
export const getSysDictionaryList = () => {
  return request({
    url: '/dictionary/list'
  })
}

/**
 * 获取字典项列表数据
 * @param dictId
 * @returns {AxiosPromise}
 */
export const getSysDictionaryDataList = dictId => {
  return request({
    url: `/dictionary/data/${dictId}/list`
  })
}
/**
 * 获取指定id的字典数据
 * @param dictId
 * @returns {AxiosPromise}
 */
export const getSysDictionaryById = dictId => {
  return request({
    url: `/dictionary/${dictId}`
  })
}

/**
 * 获取指定id的字典项数据
 * @param dictDataId
 * @returns {AxiosPromise}
 */
export const getSysDictionaryDataById = dictDataId => {
  return request({
    url: `/dictionary/data/${dictDataId}`
  })
}

/**
 * 获取指定codes的字典项列表
 * @param dictCodes 逗号分隔的字典code字符串
 * @returns {AxiosPromise}
 */
export const getSysDictionaryDatasByCodes = dictCodes => {
  return request({
    url: `/dictionary/codes/${dictCodes}`
  })
}

/**
 * 添加字典
 * @param data
 * @returns {AxiosPromise}
 */
export const addSysDictionary = data => {
  return request({
    url: '/dictionary',
    method: 'POST',
    data
  })
}

/**
 * 添加字典项数据
 * @param data
 * @returns {AxiosPromise}
 */
export const addSysDictionaryData = data => {
  return request({
    url: '/dictionary/data',
    method: 'POST',
    data
  })
}

/**
 * 修改字典
 * @param data
 * @returns {AxiosPromise}
 */
export const updateSysDictionary = data => {
  return request({
    url: '/dictionary',
    method: 'PUT',
    data
  })
}

/**
 * 修改字典项数据
 * @param data
 * @returns {AxiosPromise}
 */
export const updateSysDictionaryData = data => {
  return request({
    url: '/dictionary/data',
    method: 'PUT',
    data
  })
}

/**
 * 删除字典
 * @param dictId
 * @returns {AxiosPromise}
 */
export const deleteSysDictionary = dictId => {
  return request({
    url: `/dictionary/${dictId}`,
    method: 'DELETE'
  })
}

/**
 * 删除字典项数据
 * @param dictDataId
 * @returns {AxiosPromise}
 */
export const deleteSysDictionaryData = dictDataId => {
  return request({
    url: `/dictionary/data/${dictDataId}`,
    method: 'DELETE'
  })
}

/**
 * 删除字典项数据
 * @param dictDataId
 * @returns {AxiosPromise}
 */
export const deleteBatchSysDictionaryData = data => {
  return request({
    url: '/dictionary/data/deleteBatch',
    method: 'DELETE',
    data
  })
}
