<template>
  <el-dialog
    :model-value="modelValue"
    width="500px"
    title="修改字典"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :rules="rules" :label-width="100">
      <el-form-item label="字典名称" prop="dictName">
        <el-input
          v-model="form.dictName"
          placeholder="请输入字典名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="字典值" prop="dictCode">
        <el-input v-model="form.dictCode" placeholder="请输入字典值"></el-input>
      </el-form-item>
      <el-form-item label="排序号" prop="sortNumber">
        <el-input
          v-model="form.sortNumber"
          placeholder="请输入排序号"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getSysDictionaryById, updateSysDictionary } from '@/api/sys-dictionary'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  dictId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'dictId', 'close'])

const formRef = ref(null)

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({
  dictName: '',
  dictCode: '',
  sortNumber: 1,
  remark: ''
})

// 验证规则
const rules = ref({
  dictName: [
    {
      required: true,
      trigger: 'blur',
      message: '字典名称不能为空'
    }
  ],
  dictCode: [
    {
      required: true,
      trigger: 'blur',
      message: '字典值不能为空'
    }
  ],
  sortNumber: [
    {
      required: true,
      trigger: 'blur',
      message: '排序号不能为空'
    }
  ],
  remark: [
    {
      required: false,
      trigger: 'blur',
      message: ''
    }
  ]
})

/**
 * 获取字典数据
 */
const getSysDictionary = async () => {
  form.value = await getSysDictionaryById(props.dictId)
}
getSysDictionary()

const onSubmitClick = () => {
  formRef.value.validate(async valid => {
    if (!valid) return

    await updateSysDictionary(form.value)
    ElMessage.success('修改成功')
    closed()
  })
}
</script>

<style lang="scss" scoped></style>
