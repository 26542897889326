<template>
  <el-dialog
    :model-value="modelValue"
    width="500px"
    title="修改字典项"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :rules="rules" :label-width="100">
      <el-form-item label="字典项名称" prop="dictDataName">
        <el-input
          v-model="form.dictDataName"
          placeholder="请输入字典项名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="字典项值" prop="dictDataCode">
        <el-input
          v-model="form.dictDataCode"
          placeholder="请输入字典项值"
        ></el-input>
      </el-form-item>
      <el-form-item label="排序号" prop="sortNumber">
        <el-input
          v-model="form.sortNumber"
          placeholder="请输入排序号"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import {
  updateSysDictionaryData,
  getSysDictionaryDataById
} from '@/api/sys-dictionary'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  dictDataId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'dictDataId', 'close'])

const formRef = ref(null)

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({
  dictDataId: props.dictDataId,
  dictDataName: '',
  dictDataCode: '',
  sortNumber: 1,
  remark: ''
})

// 验证规则
const rules = ref({
  dictDataName: [
    {
      required: true,
      trigger: 'blur',
      message: '字典项名称不能为空'
    }
  ],
  dictDataCode: [
    {
      required: true,
      trigger: 'blur',
      message: '字典项值不能为空'
    }
  ],
  sortNumber: [
    {
      required: true,
      trigger: 'blur',
      message: '排序号不能为空'
    }
  ],
  remark: [
    {
      required: false,
      trigger: 'blur',
      message: ''
    }
  ]
})

/**
 * 获取字典数据
 */
const getSysDictionaryData = async () => {
  form.value = await getSysDictionaryDataById(props.dictDataId)
}
getSysDictionaryData()

const onSubmitClick = () => {
  formRef.value.validate(async valid => {
    if (!valid) return
    await updateSysDictionaryData(form.value)
    ElMessage.success('修改成功')
    closed()
  })
}
</script>

<style lang="scss" scoped></style>
