<template>
  <div>
    <el-card>
      <div style="display: flex;">
        <div class="dictPanel">
          <div
            class="tool"
            style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
          >
            <el-button size="small" type="primary" @click="addDictClick"
              >添加</el-button
            >
            <el-button size="small" type="warning" @click="editDictClick"
              >修改</el-button
            >
            <el-button size="small" type="danger" @click="delDictClick"
              >删除</el-button
            >
          </div>
          <div class="table">
            <el-table
              border
              height="calc(100vh - 220px)"
              v-loading="loading"
              highlight-current-row
              :header-cell-style="{
                background: '#fafafa',
                color: '#333333',
                padding: '12px 0'
              }"
              :cell-style="{ padding: '12px 0' }"
              :data="dictListData"
              style="width: 100%"
              @current-change="handleCurrentChange"
            >
              <el-table-column
                label="#"
                width="50"
                align="center"
                type="index"
              />
              <el-table-column property="dictName" label="字典名称" />
              <el-table-column property="dictCode" label="字典值" />
            </el-table>
          </div>
        </div>
        <div class="dictDataPanel">
          <div
            class="tool"
            style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
          >
            <el-button size="small" type="primary" @click="addDictDataClick"
              >添加</el-button
            >
            <el-button
              size="small"
              type="danger"
              @click="deleteBatchDictDataClick"
              >删除</el-button
            >
          </div>
          <div class="table">
            <el-table
              border
              height="calc(100vh - 220px)"
              v-loading="loading"
              :header-cell-style="{
                background: '#fafafa',
                color: '#333333',
                padding: '4px 0'
              }"
              :cell-style="{ padding: '4px 0' }"
              :data="dictDataListData"
              style="width: 100%"
              @selection-change="handleSelectionChange"
            >
              <el-table-column type="selection" width="55" />
              <el-table-column
                type="index"
                label="#"
                width="50"
                align="center"
              />
              <el-table-column property="dictDataName" label="字典项名称" />
              <el-table-column property="dictDataCode" label="字典项值" />
              <el-table-column
                property="sortNumber"
                label="排序号"
                width="80"
              />
              <el-table-column property="createTime" label="创建时间" />
              <el-table-column fixed="right" label="操作" width="120">
                <template #default="scope">
                  <el-button type="text" @click="editDictDataClick(scope.row)"
                    >修改</el-button
                  >
                  <el-button
                    type="text"
                    style="color: #ff4d4f"
                    @click="delDictDataClick(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </el-card>

    <add-dictionary
      v-if="addDictDialogVisible"
      v-model="addDictDialogVisible"
      @close="getdictListData"
    ></add-dictionary>

    <edit-dictionary
      v-if="editDictDialogVisible"
      v-model="editDictDialogVisible"
      :dictId="dictDataSelectItemId"
      @close="getdictListData"
    ></edit-dictionary>

    <add-dictionary-data
      v-if="addDictDataDialogVisible"
      v-model="addDictDataDialogVisible"
      :dictId="dictDataSelectItemId"
      @close="getDictDataListData"
    ></add-dictionary-data>

    <edit-dictionary-data
      v-if="editDictDataDialogVisible"
      v-model="editDictDataDialogVisible"
      :dictDataId="dictDataSelectItemDataId"
      @close="getDictDataListData"
    ></edit-dictionary-data>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  getSysDictionaryList,
  getSysDictionaryDataList,
  deleteSysDictionary,
  deleteSysDictionaryData,
  deleteBatchSysDictionaryData
} from '@/api/sys-dictionary'
import AddDictionary from './components/AddDictionary'
import EditDictionary from './components/EditDictionary'
import AddDictionaryData from './components/AddDictionaryData'
import EditDictionaryData from './components/EditDictionaryData'

const loading = ref(false)

/**
 * 获取字典列表
 * @type {ToRef<{}>}
 */
const dictListData = ref([])
// 获取数据的方法
const getdictListData = async () => {
  loading.value = true
  dictListData.value = await getSysDictionaryList()
  loading.value = false
}
getdictListData()

/**
 * 字典列表选中时触发的事件
 */
const dictDataSelectItemId = ref(0)
const dictDataSelectItemRow = ref({})
const dictDataListData = ref([])
const handleCurrentChange = async (currentRow, oldCurrentRow) => {
  dictDataSelectItemId.value = currentRow.dictId
  dictDataSelectItemRow.value = currentRow
  getDictDataListData(currentRow.dictId)
}

/**
 * 获取字典项的数据
 */
const getDictDataListData = async () => {
  dictDataListData.value = await getSysDictionaryDataList(
    dictDataSelectItemId.value
  )
}

/**
 * 显示添加字典弹出框
 */
const addDictDialogVisible = ref(false)
const addDictClick = () => {
  addDictDialogVisible.value = true
}

/**
 * 显示修改字典弹出框
 */
const editDictDialogVisible = ref(false)
const editDictClick = () => {
  if (dictDataSelectItemId.value <= 0) {
    ElMessage.error('请选择要操作的字典列表项')
    return
  }
  editDictDialogVisible.value = true
}

/**
 * 删除字典
 */
const delDictClick = () => {
  if (dictDataSelectItemId.value <= 0) {
    ElMessage.error('请选择要操作的字典列表项')
    return
  }
  ElMessageBox.confirm(
    '确认删除 [' + dictDataSelectItemRow.value.dictName + '] 字典吗？',
    '删除',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(async () => {
      await deleteSysDictionary(dictDataSelectItemId.value)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getdictListData()
    })
    .catch(() => {})
}

/**
 * 显示添加字典项弹出框
 */
const addDictDataDialogVisible = ref(false)
const addDictDataClick = () => {
  if (dictDataSelectItemId.value <= 0) {
    ElMessage.error('请选择要操作的字典列表项')
    return
  }
  addDictDataDialogVisible.value = true
}

/**
 * 显示修改字典项弹出框
 */
const dictDataSelectItemDataId = ref(0)
const editDictDataDialogVisible = ref(false)
const editDictDataClick = row => {
  dictDataSelectItemDataId.value = row.dictDataId
  editDictDataDialogVisible.value = true
}

/**
 * 删除字典项
 */
const delDictDataClick = row => {
  ElMessageBox.confirm(
    '确认删除 [' + row.dictDataName + '] 字典项吗？',
    '删除',
    {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }
  )
    .then(async () => {
      await deleteSysDictionaryData(row.dictDataId)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getDictDataListData()
    })
    .catch(() => {})
}

/**
 * 选中的字典数据列表id数组
 */
const selectItemIds = ref([])
const handleSelectionChange = val => {
  selectItemIds.value = []
  val.forEach(row => {
    selectItemIds.value.push(row.dictDataId)
  })
}

/**
 * 批量删除字典项
 */
const deleteBatchDictDataClick = () => {
  if (selectItemIds.value.length <= 0) {
    ElMessage({ type: 'warning', message: '请先选择要删除的字典项' })
    return
  }
  ElMessageBox.confirm('确认删除选中的字典项吗？', '删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteBatchSysDictionaryData(selectItemIds.value)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getDictDataListData()
    })
    .catch(() => {})
}
</script>

<style lang="scss" scoped>
.dictPanel {
  width: 450px;
  padding-right: 20px;
}
.dictDataPanel {
  flex: 1;
}
</style>
